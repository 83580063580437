(function($) {
    $(document).ready(function () {
        //show and hide more info about leadership
        $( '.leadership-single' ).click(
            function() {
                $( this ).next( '.leadership-single-pop-up' ).addClass( 'show' );
                $( '.post-type-archive-leadership' ).addClass( 'leadership-active' );
            }
        ),
        $( '.leadership-close' ).click(
            function() {
                $( this ).parent().removeClass( 'show' );
                $( '.post-type-archive-leadership' ).removeClass( 'leadership-active' );
            }
        ),

        //show and hide clinical evidence image in popup
        $( '.block-clinical-evidence figure a' ).click(
            function( event ) {
                event.preventDefault();
                var imageUrl = $( this ).attr( 'href' );
                var embedCode = '<div class="evidence-popup"><img src="/wp-content/themes/asi-startup/src/img/close-icon.svg" class="evidence-close" alt="close-evidence"><img src="' + imageUrl + '" class="evidence-image"></div>';;
                $( '.block-clinical-evidence' ).append( embedCode );
                $( this ).parents( 'body' ).addClass( 'evidence-active' );
            }
        ),
        $( '.block-clinical-evidence' ).on("click", ".evidence-close", function(){
            $( this ).parent().remove();
            $( 'body' ).removeClass( 'evidence-active' );
        })
    })
})( jQuery );